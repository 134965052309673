<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap3TheTerminal"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 3: The terminal" image-name="3-terminal.jpg" image-alt="The terminal"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What are a shell, bash, and a terminal?</p></li>
<li><p>How to launch a terminal application?</p></li>
<li><p>How to issue basics commands with bash?</p></li>
<li><p>How to improve your “developer experience” on Windows?</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>GUI: Graphical User Interface</p></li>
<li><p>CLI: Command Line Interface</p></li>
<li><p>Shell</p></li>
<li><p>Bash</p></li>
<li><p>WSL: Windows Subsystem For Linux</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The day-to-day life of a Go programmer requires the usage of the terminal. This chapter will explain what the terminal is and how to use it. If you are already familiar with it, you can skip the chapter.</p>
<div id="graphical-user-interface-gui" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Graphical User Interface (GUI) <a href="#graphical-user-interface-gui"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Operating systems like macOS, Windows, or Linux offer a rich graphical user interfaces (GUI). To launch a program installed on your computer, you generally double-click on an icon placed on your desktop. Programs will be displayed in windows with an interactive interface: menus, sidebars, buttons...</p>
<p>We say that those programs offer a graphical user interface (GUI). The vast majority of users will use programs that provide these interfaces. Graphical interfaces are easy to use and intuitive.</p>
<div id="command-line-interface-cli" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Command Line Interface (CLI) <a href="#command-line-interface-cli"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Graphical user interfaces have not always existed. The first computers did not have such capability. But how do the users of those computers managed to launch and use programs? Computers were shipped with a command-line interface. This interface is also called a “shell”. A shell is a program that can pass commands to an operating system<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. Shell is a generic term that designates such programs. The most famous shell is bash (Bourne Again Shell). Bash is shipped by default with macOS and a vast majority of Linux Distributions. Windows is also shipped by default with a shell (but it’s not bash).</p>
<div id="how-to-interact-with-a-shell-the-terminal" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> How to interact with a shell: the terminal <a href="#how-to-interact-with-a-shell-the-terminal"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The shell was presented to the user directly after startup on old computers. On modern computers, we have to launch a program to interact with a shell. This program is often called a terminal. We will see how to open a terminal with MacOs, Linux (GNOME), and Windows.</p>
<div id="macos" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> macOS <a href="#macos"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>Open the <strong>Finder</strong> application :</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/finder_icon.png')" alt="Finder Icon"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Finder Icon</figcaption>
</figure>
<ul>
<li>Then on the menu bar, click on “<strong>Go</strong>” and then “<strong>Utilities</strong>”</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/mac_utilities.png')" alt="Utilities"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Utilities</figcaption>
</figure>
<ul>
<li>A window will open. Click on “<strong>Terminal</strong>”</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/terminal_finder_mac.png')" alt="Terminal Application"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Terminal Application</figcaption>
</figure>
<ul>
<li>A new terminal will open</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/new_terminal_mac.png')" alt="Terminal Application"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Terminal Application</figcaption>
</figure>
<p>Note that I use a customized terminal. Thus you might see a different output; this is perfectly normal.</p>
<div id="linux-ubuntu" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Linux (Ubuntu) <a href="#linux-ubuntu"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>On Ubuntu, you can use the shortcut Ctrl+Alt+T</p></li>
<li><p>You can also launch the terminal with the Ubuntu Dash. Type “Terminal” and the application should appear.</p></li>
</ul>
<div id="windows" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Windows <a href="#windows"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>Click on the start button, then in the text box type “<strong>cmd</strong>” (for command prompt)</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/open_cmd_windows.png')" alt="Search for cmd application"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Search for cmd application</figcaption>
</figure>
<ul>
<li>Then click on the application <strong>cmd</strong>.</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/open_cmd_app_windows.png')" alt="Open cmd application on Windows"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Open cmd application on Windows</figcaption>
</figure>
<ul>
<li>A black window should appear; this is your terminal!</li>
</ul>
<figure>
<b-img :src="require('@/assets/images/opened_terminal_windows.png')" alt="Windows terminal"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Windows terminal</figcaption>
</figure>
<div id="cmder" class="anchor"></div>
<h3 data-number="5.3.1"><span class="header-section-number">5.3.1</span> Cmder <a href="#cmder"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The terminal and the Windows shell is not very practical on a day today basis. I advise you to install cmder<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a> To make your developer life easier on windows. Cmder is an emulator that will allow you to use commands available on Linux/MacOS. The installation process is easy (download the latest release on GitHub) then launch the installation wizard.</p>
<p>After installing cmder, launch the program “Cmder” to open your brand new terminal.</p>
<div id="bash-for-windows" class="anchor"></div>
<h3 data-number="5.3.2"><span class="header-section-number">5.3.2</span> Bash for Windows <a href="#bash-for-windows"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>By default, you cannot use bash on a Windows computer. This is not a problem, but it means that you will have to find the Windows equivalent for each macOS/Linux command. It can be cumbersome at some point because a lot of examples and tutorials on the web do not always provide equivalence for Windows.</p>
<p>Microsoft announced that you can now install a “Windows Subsystem for Linux” (WSL) on your Windows computer. This is a piece of good news because you will use bash. You can find the installation instructions on the Microsoft website: https://docs.microsoft.com/en-us/windows/wsl/install-win10 (for Windows 10).</p>
<p>I strongly advise you to install this, because it will make your life easier even if I try to give Windows equivalent for basic commands in the next sections.</p>
<div id="how-to-use-the-terminal" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> How to use the terminal <a href="#how-to-use-the-terminal"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>After you opened your terminal, you will see a black window. This is the interface where you can type commands. This is not intuitive because to type commands; you have to know them before! Each command has a name. To launch a command, you will type its name, eventually type some options and then press enter. Let’s take an example.</p>
<div id="about-the-dollar-symbol" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> About the dollar symbol <a href="#about-the-dollar-symbol"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>In the examples, you will see a line that begins with a dollar symbol. This is a convention; it means “type it into your terminal”, when you want to reproduce the examples <strong>do not type the dollar</strong>, just everything after it.</p>
<div id="macos-linux" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> MacOS / Linux <a href="#macos-linux"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s say we want to list the contents of your desktop. Type the following command (replace maximilienandile by your name).</p>
<ul>
<li>For macOS</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ ls /Users/maximilienandile/Desktop</code></pre>
<ul>
<li>For Linux</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ ls /home/maximilienandile/Desktop</code></pre>
<p>Then press enter. You will see a list of files and directories.</p>
<p>Now type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ pwd</code></pre>
<p>Press enter. The result is the following :</p>
<pre v-highlightjs><code class="go" v-pre >/Users/maximilienandile</code></pre>
<p>Here we used two commands :</p>
<ul>
<li><p><strong>ls</strong>: allow you to <strong>l</strong>i<strong>s</strong>t the contents of a directory</p></li>
<li><p><strong>pwd</strong>: allow you to <strong>p</strong>rint the <strong>w</strong>orking <strong>d</strong>irectory, (print the name of the directory you are into)</p></li>
</ul>
<div id="windows-without-linux-for-windows" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> Windows (without Linux for windows) <a href="#windows-without-linux-for-windows"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The command to list the contents of the current directory is <strong>dir :</strong></p>
<figure>
<b-img :src="require('@/assets/images/dir_win_list.png')" alt="Windows terminal"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Windows terminal</figcaption>
</figure>
<p>Each line will represent either a file or a directory. There are “&lt;DIR&gt;” In the third column, if it’s a directory. Nothing if it’s a file.</p>
<div id="windows-with-wsl-cmder" class="anchor"></div>
<h2 data-number="6.4"><span class="header-section-number">6.4</span> Windows (with WSL &amp; Cmder) <a href="#windows-with-wsl-cmder"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>If you have installed <strong>WSL</strong> and <strong>cmder</strong>, the first step is to launch cmder (via the windows menu, for example). Then you can launch <strong>bash</strong> by typing this command :</p>
<pre v-highlightjs><code class="go" v-pre >$ bash</code></pre>
<p>And press enter. Now you are using bash! (congratulations). To list elements in your home directory, simply type :</p>
<pre v-highlightjs><code class="go" v-pre >$ ls /mnt/c/Users/maxou123</code></pre>
<p>(where maxou123 is your username).</p>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="7.1"><span class="header-section-number">7.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the command to list the contents of a directory?</p></li>
<li><p>What is a terminal?</p></li>
<li><p>Give the name of a well-known shell.</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="7.2"><span class="header-section-number">7.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is the command to list the contents of a directory?</p>
<ol type="1">
<li><p>ls (for UNIX sytems)</p></li>
<li><p>dir for Windows</p></li>
</ol></li>
<li><p>What is a terminal?</p>
<ol type="1">
<li>A terminal is a program that offers an interface to use a shell</li>
</ol></li>
<li><p>Give the name of a well-known shell.</p>
<ol type="1">
<li>bash</li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Key takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Graphical User Interfaces have not always existed</p></li>
<li><p>To interact with computers, we can use a Graphical Interface or a Command line Interface. (CLI)</p></li>
<li><p>To use a CLI, we have to open a terminal application that offers an interface to interact with a shell</p></li>
<li><p>Bash is a shell.</p></li>
<li><p>You can launch commands by typing the command name and eventually options, then press enter.</p></li>
<li><p>We will use the terminal to launch go-specific commands.</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>http://www.catb.org/jargon/html/S/shell.html<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>https://cmder.net/ , github repository : https://github.com/cmderdev/cmder<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap3TheTerminal"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap2TheGoLanguage'}">
									<p><u><small>Previous</small></u></p>
									<p><small>The Go Language</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap4SetupYourDevEnvironment'}">
									<p><u><small>Next</small></u></p>
									<p><small>Setup your dev environment</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "The terminal - Practical Go Lessons"
const description = "The day-to-day life of a Go programmer requires the usage of the terminal. This chapter will explain what the terminal is and how to use it."

export default {
  name: "Chap3TheTerminal",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
